/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var thisPage = document.location.href;
        var thisPath = document.location.href.replace(/https?\:\/\/[^\/]+/,'');
        $(".dropdown-menu a[href*='\\#']").each(function() {
            $(this).attr('href', $(this).attr('href').replace(thisPage,'').replace(thisPath,''));
            $(this).parent().removeClass("active");
        });
        $(".navbar-collapse.collapse").on("click", "a", function() {
            $(this).parents(".collapse.in").removeClass("in");
        });

        $('a[href*=\\#]:not([href=\\#]):not([data-toggle])').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                       scrollTop: target.offset().top -30
                    }, 500);
                    $(this).parents(".collapse.in").collapse('hide');
                    return false;
                }
            }
        });

        $(".btn-default[href*=contact]").magnificPopup({
            items: {
                src: $("#contact-form"),
                type: 'inline'
            }
        });

        $('.gallery').each(function() {
            $(this).magnificPopup({
        		delegate: 'a',
        		type: 'image',
        		tLoading: 'Loading image #%curr%...',
        		mainClass: 'mfp-img-mobile',
        		gallery: {
        			enabled: true,
        			navigateByImgClick: true,
        			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        		},
        		image: {
        			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        			titleSrc: function(item) {
        				return item.el.parents(".gallery-item").find(".gallery-caption").text();
        			}
        		}
        	});
        });
        $('.filmstrip').each(function() {
            $(this).magnificPopup({
        		delegate: 'a.filmstrip-item',
        		type: 'image',
        		tLoading: 'Loading image #%curr%...',
        		mainClass: 'mfp-img-mobile',
        		gallery: {
        			enabled: true,
        			navigateByImgClick: true,
        			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        		},
        		image: {
        			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        			titleSrc: function(item) {
        				return item.el.parents(".gallery-item").find(".gallery-caption").text();
        			}
        		}
        	});
        });


        $(".filmstrip").find("li:first").addClass("first");

        $(".filmstrip-next").on("click", function() {
            if($(this).hasClass("disabled")) {
                return ;
            }
            var film = $(this).parents(".filmstrip");
            var thisItem = film.find(".first");
            if(thisItem.size() == 0) {
                thisItem = film.find("li:first");
            }

            var nextItem = thisItem.next("li");
            if(nextItem.size() == 0) {
                nextItem = film.find("li:first");
            }

            if(nextItem.size() > 0) {
                var ul = film.find("ul");

                var l = 0;
                nextItem.prevAll("li").each(function() {
                    l += parseInt($(this).outerWidth(),10);
                });
                ul.css("left", "-" + l + 'px');
                film.find(".first").removeClass("first");
                nextItem.addClass("first");
            }
            checkNextPrev(film);
        });

        $(".filmstrip-prev").on("click", function() {
            if($(this).hasClass("disabled")) {
                return ;
            }
            var film = $(this).parents(".filmstrip");
            var thisItem = film.find(".first");
            if(thisItem.size() == 0) {
                thisItem = film.find("li:last");
            }

            var nextItem = thisItem.prev("li");
            if(nextItem.size() == 0) {
                nextItem = film.find("li:last");
            }

            if(nextItem.size() > 0) {
                var ul = film.find("ul");

                var l = 0;
                nextItem.prevAll("li").each(function() {
                    l += parseInt($(this).outerWidth(),10);
                });
                ul.css("left", "-" + l + 'px');
                film.find(".first").removeClass("first");
                nextItem.addClass("first");
            }
            checkNextPrev(film);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('[data-spy="scroll"]').each(function () {
          var $spy = $(this).scrollspy('refresh');
        });

        $('.animated[data-animation]').onScreen({
            doIn: function() {
                $(this).addClass($(this).attr("data-animation"));
            }
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function checkNextPrev(film) {
    var thisItem = film.find("li.first");
    if(thisItem.next("li").size() == 0) {
        film.find(".filmstrip-next").addClass("disabled");
    } else {
        film.find(".filmstrip-next").removeClass("disabled");
    }
    if(thisItem.prev("li").size() == 0) {
        film.find(".filmstrip-prev").addClass("disabled");
    } else {
        film.find(".filmstrip-prev").removeClass("disabled");
    }
}

function initMap() {
    $(".map").each(function() {
        var myLatLng = {lat: parseFloat($(this).attr('data-latitude')), lng: parseFloat($(this).attr('data-longitude'))};

        var map = new google.maps.Map($(this).get(0), {
            zoom: 14,
            center: myLatLng
        });

        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: $(this).attr('data-title')
        });
    });
}